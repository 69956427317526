import { makeStyles } from '@mui/styles';
import {
  AsyncLoadingSection,
  SingleSectionPageCard,
  TaskPageHeaderLayout,
} from 'Components/Display';
import { SmartInputBlock } from 'Components/Form';
import { useEnums } from 'Context';
import { InputType } from 'Models/FormModels';
import { ColSize, Referral } from 'Models/SharedModels';
import { ENDPOINTS } from 'Models/Templates/Routes/Routes';
import React, { Fragment, useState } from 'react';
import { Fade, Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router';
import { Row } from 'reactstrap';
import {
  useDataFetcher,
  addEditTitle,
  useIdParam,
  useAddEditSuccessHandler,
  defaultDataTransform,
  buildQueryUrl,
  buildEndpoint,
} from 'Utilities';

export const AddEditReferral: React.FC = () => {
  const entityName = 'Referral';
  const [id, isAdd] = useIdParam();
  const { claimid } = useParams<any>();

  const handleSuccess = useAddEditSuccessHandler(entityName, isAdd);
  const [data, loadingStatus] = useDataFetcher<Referral>(
    buildQueryUrl(buildEndpoint(ENDPOINTS.referrals.get), {
      id: id || '',
      claimId: claimid,
    }),
    new Referral(),
    defaultDataTransform,
    Referral
  );

  return (
    <AsyncLoadingSection loadingStatus={loadingStatus} useMask>
      <TaskPageHeaderLayout
        title={addEditTitle(entityName, isAdd)}
        formProviderProps={{
          formModel: data,
          endpoint: ENDPOINTS.referrals.base,
          handleSuccess: handleSuccess,
        }}
      >
        <ReferralsAddEditFormContent isReviewed={data.reviewed} data={data} />
      </TaskPageHeaderLayout>
    </AsyncLoadingSection>
  );
};

export type ReferralsAddEditProps = {
  isReviewed: boolean | undefined;
  data: Referral;
};

const ReferralsAddEditFormContent: React.FC<ReferralsAddEditProps> = ({
  isReviewed,
  data,
}) => {
  const { caseStatus, referralType, firstLevelReviewStatus, imeStatus } =
    useEnums();

  const [key, setKey] = useState('primary');

  const useStyles = makeStyles({
    infoLabel: {
      fontSize: 'small',
      color: 'gray',
      marginBottom: 15,
      display: 'inherit',
    },
    infoLabelSpan: {
      fontSize: 'small',
      color: 'gray',
      marginLeft: 15,
      position: 'absolute',
    },
  });
  const [id, isAdd] = useIdParam();
  const classes = useStyles();

  let typeOfEvalReadEndpoint = buildEndpoint(
    ENDPOINTS.clients.endpointString('typeOfEvalDropdownItems'),
    isAdd ? '00000000-0000-0000-0000-000000000000' : data.clientId
  );

  return (
    <Fragment>
      <SingleSectionPageCard cardTitle="Details" centerContent inputSection>
        <Tabs
          transition={Fade}
          id="controlled-tab-example"
          activeKey={key}
          onSelect={(k) => setKey(k as string)}
          className="mb-3"
        >
          <Tab eventKey="primary" title="Primary Information">
            <Row>
              <SmartInputBlock
                name="claimId"
                type={InputType.Text}
                hidden
                required
                colProps={{ xs: ColSize.OneThird }}
              />
              <SmartInputBlock
                name="referralSourceId"
                label="Referral Source"
                type={InputType.Text}
                hidden
                required
              />
              <SmartInputBlock
                name="physicianId"
                type={InputType.Text}
                hidden
                required
              />
            </Row>
            <Row>
              <SmartInputBlock
                name="name"
                label="Submitter Name"
                type={InputType.Text}
                required
                colProps={{ xs: ColSize.OneThird }}
              />
              <SmartInputBlock
                name="referralType"
                label="Referral Type"
                data={referralType}
                type={InputType.EnumDropdown}
                colProps={{ xs: ColSize.OneThird }}
              />
              <SmartInputBlock
                name="evalType"
                label="Type Of Evaluation"
                readEndpoint={typeOfEvalReadEndpoint}
                type={InputType.DropDown}
                colProps={{ xs: ColSize.OneThird }}
              />
            </Row>
            <Row>
              <SmartInputBlock
                name="providerRequestingTreatment"
                label="Provider Requesting Treatment"
                type={InputType.Text}
                colProps={{ xs: ColSize.OneThird }}
              />
              <SmartInputBlock
                name="providerPhone"
                label="Provider Phone"
                type={InputType.PhoneNumber}
                colProps={{ xs: ColSize.OneThird }}
              />
              <SmartInputBlock
                name="reviewerSpeciality"
                label="Reviewer Speciality"
                type={InputType.Text}
                colProps={{ xs: ColSize.OneThird }}
              />
            </Row>
            <Row>
              <SmartInputBlock
                name="specialInstructions"
                label="Special Instructions"
                type={InputType.TextArea}
                colProps={{ xs: ColSize.OneThird }}
              />
              <SmartInputBlock
                name="contactAllowed"
                label="Contact Allowed"
                type={InputType.Checkbox}
                colProps={{ xs: ColSize.OneThird }}
              />

              <SmartInputBlock
                name="firstLevelReviewStatus"
                label="First Level Review Status"
                data={firstLevelReviewStatus}
                type={InputType.EnumDropdown}
                colProps={{ xs: ColSize.OneThird }}
              />
            </Row>
            <Row>
              <SmartInputBlock
                name="imeStatus"
                label="IME Status"
                data={imeStatus}
                type={InputType.EnumDropdown}
                colProps={{ xs: ColSize.OneThird }}
              />
              <SmartInputBlock
                name="dueDate"
                label="Due Date"
                type={InputType.DatePicker}
                colProps={{ xs: ColSize.OneThird }}
              />
              <SmartInputBlock
                name="dueDate"
                label="Due Time"
                type={InputType.TimePicker}
                colProps={{ xs: ColSize.OneThird }}
              /></Row>
              <Row>
              <SmartInputBlock
                name="referralNumber"
                label="Referral Number"
                type={InputType.Text}
                colProps={{ xs: ColSize.OneThird }}
              />
              <SmartInputBlock
                name="externalAdjuster"
                label="External Adjuster"
                type={InputType.Text}
                colProps={{ xs: ColSize.OneThird }}
              />
              <SmartInputBlock
                  name="physician"
                  label="Physician"
                  type={InputType.AsyncDropDown}
                  readEndpoint={ENDPOINTS.physicians.endpointString(
                    'DropdownItems'
                  )}
                  colProps={{ xs: ColSize.OneThird }}
                />
            </Row>
          </Tab>

          <Tab eventKey="case" title="Case Operations">
            {isReviewed && (
              <div>
                <span className={classes.infoLabel}>
                  <strong>Note:</strong> Open, Re-Open, Closed options will be
                  seen only if all reports of this claimant are marked as
                  "Reviewed".
                </span>
                <Row>
                  <SmartInputBlock
                    name="caseStatus"
                    label="Case Status"
                    data={caseStatus}
                    type={InputType.EnumDropdown}
                    colProps={{ xs: ColSize.Half }}
                  />
                </Row>
              </div>
            )}
            <Row>
              <SmartInputBlock
                name="closedDate"
                label="Date Closed"
                type={InputType.DatePicker}
                colProps={{ xs: ColSize.Half }}
              />
            </Row>
            <Row>
              <span className={classes.infoLabelSpan}>
                <strong>Note:</strong> Mark as check to send the case to nurse
                for UR First Level.
              </span>
              <SmartInputBlock
                name="referForFirstLevelUtilizationReview"
                label="Refer For First Level Utilization Review"
                type={InputType.Checkbox}
                colProps={{ xs: ColSize.Half }}
              />
            </Row>
            <Row>
              <span className={classes.infoLabelSpan}>
                <strong>Note:</strong> If this is checked then it means is sent
                to doctor for First Level UR. May be for denial approval.
              </span>
              <SmartInputBlock
                name="sentToDoctor"
                label="Sent To Doctor"
                type={InputType.Checkbox}
                colProps={{ xs: ColSize.Half }}
              />
            </Row>
            <Row>
              <SmartInputBlock
                name="flurNurse"
                label="FLUR Nurse"
                type={InputType.AsyncDropDown}
                useQuery
                otherparams={{ isNurse: true }}
                readEndpoint={ENDPOINTS.users.dropdownItems}
                colProps={{ xs: ColSize.Half }}
              />
            </Row>
          </Tab>
        </Tabs>
      </SingleSectionPageCard>
    </Fragment>
  );
};